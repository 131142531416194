import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container, Button } from 'components/common'
import dev from 'assets/illustrations/skills.svg'
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'

export const Skills = () => (
  <Wrapper id="about">
    <SkillsWrapper as={Container}>
      <Thumbnail>
        <img src={dev} alt="I’m Abduselam and I’m a Software developer!" />
      </Thumbnail>
      <Details>
        <h1>Hi There!</h1>
        <p>
          I am a software developer with experience in Javascript, and worked using React, React native, Gatsbyjs, netlify ci and serverless functions, I've used MongoDb and FaunaDb.
        </p>
        <Button as={AnchorLink} href="#contact">
          Hire me
        </Button>
      </Details>
    </SkillsWrapper>
  </Wrapper>
)
